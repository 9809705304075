import { graphql, useStaticQuery } from "gatsby"
import React, { useRef } from "react"
import { useAppDispatch } from "../../../store/context/app-context"
import useElementScrollPosition from "../../../utils/useElementScrollPosition"
import OfferForm from "../../OfferForm"
import Button from "../../UI/Button"
import Container from "../../UI/Container"
import Modal from "../../UI/Modal/Index"
import useModal from "../../UI/Modal/useModal"
import {
  Bottom,
  Content,
  Feedback,
  FeedbackTitle,
  FeedbackWrapper,
  Gallery,
  GalleryImage,
  InnerWrapper,
  Title,
  Wrapper,
} from "./styles"

const CateringData = () => {
  const { isShowing, setIsShowing, toggle } = useModal()
  const ref = useRef()
  const elRef = useRef()
  const dispatch = useAppDispatch()
  useElementScrollPosition(response => {
    // console.log("res", response)
    dispatch({
      type: "SET_POSITION",
      payload: response.position,
    })
  }, elRef)

  const data = useStaticQuery(graphql`
    query {
      wpPage(slug: { in: "catering" }) {
        blocks {
          name
          originalContent
        }
      }
      allWpReview {
        edges {
          node {
            content
            tags {
              nodes {
                slug
                id
              }
            }
            blocks {
              originalContent
              name
            }
          }
        }
      }
    }
  `)

  let i = 0

  if (typeof document === "undefined") return null

  return (
    <Wrapper>
      <InnerWrapper>
        <Title>
          {data.wpPage.blocks.map(block => {
            i++
            if (block.name === "core/heading") {
              return (
                <div
                  key={i}
                  dangerouslySetInnerHTML={{ __html: block.originalContent }}
                />
              )
            }
          })}
        </Title>
        <Content>
          {data.wpPage.blocks.map(block => {
            i++
            if (block.name === "core/paragraph") {
              return (
                <div
                  key={i}
                  dangerouslySetInnerHTML={{ __html: block.originalContent }}
                />
              )
            }
          })}
        </Content>
      </InnerWrapper>
      <div ref={elRef}>
        <Bottom>
          <Container>
            <Gallery>
              {data.wpPage.blocks.map(block => {
                i++
                let node
                if (block.name === "core/gallery") {
                  let a = block.originalContent
                  let images = []

                  var div = document.createElement("div")
                  div.innerHTML = a
                  Array.from(div.querySelectorAll("picture")).forEach(function (
                    div
                  ) {
                    node = div.querySelectorAll("img")
                    images.push(node)
                  })
                  if (!node) return null
                  return images.map(img => {
                    return (
                      <GalleryImage key={i}>
                        <img src={img[0]?.currentSrc} alt="" />
                      </GalleryImage>
                    )
                  })
                }
              })}
            </Gallery>
            <FeedbackWrapper>
              {data.allWpReview.edges.map(edge => {
                i++
                return edge.node.tags.nodes.map(tag => {
                  if (tag.slug === "catering") {
                    return (
                      <Feedback key={tag.id}>
                        {edge.node.blocks.map(block => {
                          if (block.name === "core/paragraph") {
                            return (
                              <div
                                key={i}
                                dangerouslySetInnerHTML={{
                                  __html: block.originalContent,
                                }}
                              />
                            )
                          } else if (block.name === "core/heading") {
                            return (
                              <FeedbackTitle>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: block.originalContent,
                                  }}
                                />
                              </FeedbackTitle>
                            )
                          }
                        })}
                      </Feedback>
                    )
                  }
                })
              })}
            </FeedbackWrapper>
            <Button
              onClick={toggle}
              title="Võta ühendust"
              size="large"
              color="light"
              centered
            />
            <Modal
              setIsShowing={setIsShowing}
              isShowing={isShowing}
              toggle={toggle}
              ref={ref}
              background="light"
            >
              <OfferForm />
            </Modal>
          </Container>
        </Bottom>
      </div>
    </Wrapper>
  )
}

export default CateringData
