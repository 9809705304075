import { useEffect } from "react"

const useElementScrollPosition = (callback, ref) => {
  useEffect(() => {
    const listener = () => {
      callback({ position: ref?.current?.getBoundingClientRect()?.top })
    }
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", listener)
      return () => window.removeEventListener("scroll", listener)
    }
  }, [])

  return null
}

export default useElementScrollPosition
