import styled from "styled-components"
import taldrik from "../../../assets/images/taldrik2.png"

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  @media (min-width: 1200px) {
    overflow: unset;
  }
`
export const InnerWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: -100px;
    height: 171px;
    width: 285px;
    background-image: url(${taldrik});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  @media (max-width: 374px) {
    padding-bottom: 4rem;
    min-height: 130vh;
  }
  @media only screen and (min-width: 576px) {
    max-width: 540px;
    margin: auto;
  }
  @media only screen and (min-width: 768px) {
    max-width: 720px;
    min-height: 80vh;
    overflow: unset;
    padding: 0;
    &:after {
      right: 0;
      height: 220px;
      width: 375px;
    }
  }
  @media only screen and (min-width: 992px) {
    max-width: 960px;
    padding: 0 1rem;
  }
  @media only screen and (min-width: 1200px) {
    flex-direction: row;
    margin: 0 auto;
    margin-top: auto;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1140px;
    height: 100vh;
    min-height: 760px;
    &:after {
      content: none;
    }
  }
  @media only screen and (min-width: 1400px) {
    max-width: 1320px;
  }
`

export const Title = styled.div`
  position: relative;
  h2 {
    color: #fff;
    font-family: "JosefinSans-Bold";
    font-weight: 700;
    font-size: 31px;
    width: 240px;
  }
  @media (min-width: 768px) {
    h2 {
      font-size: 44px;
      width: 400px;
    }
  }
  @media only screen and (min-width: 1200px) {
    margin-top: auto;
    height: 60vh;
    flex: 0 0 50%;
    h2 {
      font-size: 82px;
      margin-top: -30px;
      line-height: 97px;
      height: 100%;
      margin-bottom: 0;
      width: auto;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 37vh;
      width: 630px;
      background-image: url(${taldrik});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  @media only screen and (min-width: 1400px) {
    h2 {
      font-size: 90px;
    }
    &:after {
      width: 740px;
      height: 41vh;
    }
  }
  @media (min-width: 1200px) and (max-height: 800px) {
    &:after {
      height: 36vh;
    }
  }
  @media only screen and (min-width: 1600px) {
    h2 {
      padding-left: 2rem;
    }
  }
`

export const Content = styled.div`
  h3 {
    color: #fff;
    font-family: "JosefinSans-Regular";
  }
  p {
    font-family: "JosefinSans-Regular";
    font-weight: 400;
    color: #fff;
  }
  @media only screen and (min-width: 1200px) {
    margin-top: auto;
    flex: 0 0 43%;
    height: 63.5vh;
    p {
      line-height: 35px;
      font-size: 18px;
    }
    div {
      margin-bottom: 4rem;
    }
  }
  @media only screen and (min-width: 1600px) {
    flex: 0 0 40%;
  }
`
export const Bottom = styled.div`
  background-color: #1e2123;
  padding-top: 3rem;
  padding-bottom: 5rem;
`
export const Gallery = styled.div`
  @media only screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (min-width: 1200px) {
    padding: 0 1rem;
  }
`

export const GalleryImage = styled.div`
  @media only screen and (min-width: 768px) {
    flex: 0 0 48%;
    img {
      display: block;
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    flex: 0 0 49%;
  }
`
export const FeedbackWrapper = styled.div`
  padding: 2rem 0;
  margin-bottom: 3rem;
  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media only screen and (min-width: 1200px) {
    margin-bottom: 4rem;
  }
`
export const Feedback = styled.div`
  position: relative;
  padding: 1rem;
  padding-bottom: 2rem;
  border: 1px solid #707070;
  border-radius: 0.5rem;
  margin: 1rem 0.5rem;
  p {
    font-family: "JosefinSans-Regular";
    color: #e2c6a9;
  }
  @media (min-width: 1024px) {
    width: 48%;
  }
  @media only screen and (min-width: 1200px) {
    width: 332px;
    padding: 1.5rem 1rem;
    padding-bottom: 2rem;
    margin: 0 1rem;
    p {
      margin: 0;
      font-size: 25px;
      line-height: 30px;
    }
  }
  @media only screen and (min-width: 1400px) {
    width: 390px;
    padding: 2rem;
  }
`

export const FeedbackTitle = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  h3 {
    font-size: 16px;
    font-family: "JosefinSans-Regular";
    font-weight: 400;
    color: #fff;
    margin: 0;
  }
  @media (min-width: 1200px) {
    h3 {
      font-size: 17px;
    }
  }
`
