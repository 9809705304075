import React from "react"
import CateringData from "../components/Pages/Catering"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

export default function Catering({ data }) {
  const page = graphql`
    query {
      wpPage(slug: { in: "catering" }) {
        title
      }
    }
  `

  return (
    <Layout background="light">
      <SEO title="Catering" />
      <CateringData title={page.wpPage?.title} />
    </Layout>
  )
}
